import "swiper/css";

const CustomSwiperSlide = ({ children, styles, ...rest }) => {
  return (
    <swiper-slide {...rest} style={styles}>
      {children}
    </swiper-slide>
  );
};

export default CustomSwiperSlide;
