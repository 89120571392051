import BlogCard from "@/components/Shared/BlogCard";
import CustomSwiper from "@/components/Swiper/CustomSwiper";
import CustomSwiperSlide from "@/components/Swiper/CustomSwiperSlide";

export default function BlogSwiper({ activeContent, style, color }) {
  return (
    <div>
      <CustomSwiper
        view={{ desktop: 3.5, laptop: 2.5, md: 1.1, mobile: 1.1 }}
        space={14}
        autoplay={false}
      >
        {activeContent?.map((data, idx) => (
          <CustomSwiperSlide key={idx}>
            <BlogCard
              img={
                data.image
                  ? data.image
                  : "/site-assets/placeholders/img-placeholder.jpg"
              }
              category={data.content_type}
              title={data.title}
              info={data.description}
              style={style}
              url_slug={data.url_slug}
              color={color}
            />
          </CustomSwiperSlide>
        ))}
      </CustomSwiper>
    </div>
  );
}
